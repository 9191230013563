export const page = {
    computed: {
        currentPage() {
            return this.$store.state.pages.find( page => page.id === this.$route.meta.pageId)
        },
        currentPageNum() {
            return this.currentPage.bookletPageNum
        },
    }
}
