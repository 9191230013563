import {firestore} from "@/mixins/firebase";
import {page} from "@/mixins/page";
import {transformOrder} from "@/mixins/order";
import axios from 'axios';

export const form = {
    mixins: [
        firestore,
        page,
        transformOrder
    ],
    data: () => ({
        valid: true,
        anagramAnswerRules: [
            v => !!v || 'Answer is required',
            v => (v || '').length <= 20 || "A maximum of 20 characters is allowed"
        ],
        answerRules: [
            v => !!v || 'Answer is required'
        ],
        captionRules: [
            v => !!v || 'Caption is required',
            v => (v || '').length <= 65 || "A maximum of 65 characters is allowed"
        ],
        dateRules: [
            v => !!v || 'Date is required'
        ],
        farewellRules: [
            v => !!v || 'Farewell is required',
            v => (v || '').length <= 400 || "A maximum of 400 characters is allowed"
        ],
        hintRules: [
            v => !!v || 'Hint is required',
            v => (v || '').length <= 30 || "A maximum of 30 characters is allowed"
        ],
        introRules: [
            v => !!v || 'Intro is required',
            v => (v || '').length <= 270 || "A maximum of 270 characters is allowed"
        ],
        locationRules: [
            v => !!v || 'Location is required',
            v => (v || '').length <= 40 || "A maximum of 40 characters is allowed"
        ],
        secretMessageRules: [
            v => !!v || 'Secret message is required',
            v => (v || '').length <= 250 || "A maximum of 250 characters is allowed",
            v => /^[A-Za-z,.\s]+$/.test(v) || 'This field only accepts A-Z, a-z, whitespace, commas & full stops.'
        ],
        nameRules: [
            v => !!v || 'Name is required',
            v => (v || '').length <= 20 || "A maximum of 20 characters is allowed"
        ],
        penTextRules: [
            v => !!v || 'Pen text is required',
            v => (v || '').length <= 40 || "A maximum of 40 characters is allowed"
        ],
        question110Rules: [
            v => !!v || 'Question is required',
            v => (v || '').length <= 110 || "A maximum of 110 characters is allowed"
        ],
        question120Rules: [
            v => !!v || 'Question is required',
            v => (v || '').length <= 120 || "A maximum of 120 characters is allowed"
        ],
        specialMemoryRules: [
            v => !!v || 'Special memory is required',
            v => (v || '').length <= 350 || "A maximum of 350 characters is allowed"
        ],
        statementRules: [
            v => !!v || 'Statement is required',
            v => (v || '').length <= 90 || "A maximum of 90 characters is allowed"
        ],
        themeRules: [
            v => !!v || 'Theme is required'
        ],
        required120Rules: [
            v => !!v || 'This field is required',
            v => (v || '').length <= 120 || "A maximum of 120 characters is allowed"
        ],
    }),
    methods: {
        currentFormIsValid() {
            if (typeof this.$refs.form === "undefined") {
                console.log('No form to validate.')
                return true
            }
            console.log('Validating current form...')
            if (!this.$refs.form.validate()) {
                console.log('Current form is invalid.')
                return false
            }
            console.log('Current form is valid.')
            return true
        },
        // TODO: Refactor as save() rather than submit().
        submit(challengeId = null) {

            if (typeof challengeId === "string") {
                // Form being submitted is a challenge.
                console.log("challengeId:", challengeId)
                // Record whether this challenge is valid or not.
                this.$store.commit("setActivity", {
                    challengeId: challengeId,
                    valid: this.currentFormIsValid()
                })
            }

            if (this.currentPage.complete !== null) {
                // Mark this page as complete/incomplete, according to validation result.
                this.currentPage.complete = this.currentFormIsValid()
            }

            if (this.currentPage.id === "weddingWall") {
                // Mark the wedding wall page as complete/incomplete, according to validation result.
                // NOte that this is intentionally separate from the way other pages are marked as complete.
                // The completion of the wedding wall page should not influence whether an order can be submitted.
                // this.$store..complete = this.currentFormIsValid()
                this.$store.commit("setWeddingWallPageComplete", this.currentFormIsValid())
            }

            if (!this.currentFormIsValid()) {
                return
            }

            this.updateAppStateInFirestore()
        },
        clear() {
            this.$root.$confirm(
                    'Clear all fields',
                    'This will clear all data in all fields on this page. Are you sure?'
            )
                    .then((confirm) => {
                        if (confirm) {
                            if ("imageUpload" in this.$refs) {
                                // Only run if the "imageUpload" key exists in this.$refs
                                this.$refs.imageUpload.deleteImage()
                            }
                            this.$refs.form.reset()
                        }
                    })
        },
        preview( options ) {

            if (!this.currentFormIsValid()) {
                return
            }
            this.updateAppStateInFirestore()

            // Init
            this.$store.commit("setPreviewError", 0)
            this.$store.commit("setPreviewImagesReady", false)
            this.$store.commit("setPreviewImages", [])
            // Launch preview dialog.
            this.$store.commit("setDialogPreview", true)

            const pathPrefix = process.env.VUE_APP_PRINT_SERVICE_PATH
            const orderToPost = this.transformOrder(options)

            // Initial request to generate preview images.

            console.log("Requesting generation of preview images...")
            axios.post(`${pathPrefix}/api/order/generate-preview`,
                orderToPost
            )
                    .then(response => {
                        console.log("Response:", response)
                        // Request for generated preview images.
                        this.requestUpdate()
                    })
                    .catch(error => {
                        console.log(error)
                        if (error.response.status === 500) {
                            console.log("Preview API error (code: 500)")
                            this.$store.commit("setPreviewError", error.response.status)
                        }
                    })

        },
        fieldValueChanged() {
            // A field value was changed, mark the current page as incomplete.
            this.currentPage.complete = false
        },
        requestUpdate() {
            const pathPrefix = process.env.VUE_APP_PRINT_SERVICE_PATH
            console.log("Requesting update on preview images...")
            axios.post(`${pathPrefix}/api/order/request-update`, {
                sourceOrderId: this.$store.state.order.sourceOrderId
            }).then(response => {
                console.log("Response:", response)
                this.$store.commit("setPreviewImagesReady", true)
                this.$store.commit("setPreviewImages", response.data.pages)
                clearTimeout(recursiveRequestUpdate)
            }).catch(error => {
                if (error.response.status === 400) {
                    console.log("Preview API: Images still being prepared...")
                } else {
                    console.log(error)
                }
            })
            // Recursive call to this very method. This is how we repeat the API call until the images are ready.
            const recursiveRequestUpdate = setTimeout(this.requestUpdate, 3000)
        },
    },
    computed: {
        readOnlyMode: {
            get: function () {
                return this.$store.state.readOnlyMode
            },
            set(value) {
                this.$store.commit("setReadOnlyMode", value)
            }
        },
    }
}
